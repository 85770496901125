.contextMenu {
position: fixed;
background: white;
/* box-shadow: 0px 2px 10px #999999; */
}

.contextMenu--option {
    padding: 6px 5px 5px 10px;
    min-width: 200px;
    cursor: default;
    font-size: 12px;
}
.contextMenu--option:hover {
        background: rgb(200, 200, 200);
            color: white;
    }
    
.contextMenu--option:active {
    color: #e9e9e9;
    background: linear-gradient(to top, #555, #444);
}
    
.contextMenu--option__disabled {
    color: #999999;
    pointer-events: none;
}


.contextMenu--separator {
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 0 0 0 0;
}


